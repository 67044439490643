import { configureStore } from '@reduxjs/toolkit';
import timeSlice from './timeSlice';

const store = configureStore({
  reducer: {
    time: timeSlice,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
