import { getCityName, getCountryName, Place } from '../data/places';
import flagForPlace from '../data/flag';

interface PlaceBoxProps {
  place: Place;
}

export default function PlaceBox(props: PlaceBoxProps) {
  const { place } = props;

  return (
    <div className="w-40 h-40
      p-4
      flex flex-col gap-1
      bg-gradient-to-br from-indigo-800 to-gray-900
      rounded-xl shadow-indigo-700/40 shadow-xl
      hover:shadow-2xl hover:shadow-cyan-400 hover:z-10
      relative top-0 hover:-top-3 transition-[top] duration-300">
      <span className="text-6xl">{flagForPlace(place)}</span>
      <span>{getCountryName(place)}</span>
      {place.city &&
        <span className="italic">
          {getCityName(place)}
        </span>
      }
    </div>
  );
}
