import { useSelector } from 'react-redux';
import { NEW_YEARS_MIDNIGHT_MILLIS, selectMillisUntilMidnight } from '../redux/timeSlice';
import { useMemo } from 'react';

const dateFormatter = new Intl.DateTimeFormat('de-DE', {
  timeStyle: 'short',
});

interface TimerProps {
  utcOffset: number;
}

function hoursToMillis(hours: number): number {
  return hours * 60 * 60 * 1000;
}

function formatOffsetMidnightInLocalTime(utcOffsetMillis: number): string {
  const utcTimeWhenTheirMidnight = NEW_YEARS_MIDNIGHT_MILLIS - utcOffsetMillis;
  return dateFormatter.format(utcTimeWhenTheirMidnight);
}

function isDurationCloseEnoughToNow(millis: number): boolean {
  return millis > -10000 && millis < 1000;
}

function formatDuration(millis: number): string {
  if (isDurationCloseEnoughToNow(millis)) return 'JETZT!';

  const secondsToGo = Math.floor(Math.abs(millis) / 1000);
  const seconds = secondsToGo % 60;
  const minutesToGo = Math.floor(secondsToGo / 60);
  const minutes = minutesToGo % 60;
  const hoursToGo = Math.floor(minutesToGo / 60);
  const hours = hoursToGo % 24;
  const daysToGo = Math.floor(hoursToGo / 24);

  let output = (millis > 0) ? 'in ' : 'vor ';
  if (daysToGo != 0) output += `${daysToGo} Tag `;
  if (hours != 0) output += `${hours} Std. `;
  if (minutes != 0) output += `${minutes} Min. `;
  if (seconds != 0) output += `${seconds} Sek.`;

  return output;
}

export default function Timer(props: TimerProps) {
  const { utcOffset } = props;
  const utcOffsetMillis = hoursToMillis(utcOffset);

  const theirMidnightInLocalTime = useMemo(
    () => formatOffsetMidnightInLocalTime(utcOffsetMillis),
    [utcOffset],
  );

  const millisUntilUTCMidnight = useSelector(selectMillisUntilMidnight);
  const millisUntilTZMidnight = millisUntilUTCMidnight - utcOffsetMillis;
  const isNow = isDurationCloseEnoughToNow(millisUntilTZMidnight);

  return (
    <div className="grid grid-cols-[2fr_0_2fr] gap-x-4">
      <span className="justify-self-end">
        um {theirMidnightInLocalTime} Uhr dt. Zeit
      </span>
      <span>|</span>
      <span className={`justify-self-start ${isNow && 'font-bold italic'}`}>
        {formatDuration(millisUntilTZMidnight)}
      </span>
    </div>
  );
}
