import type { Localization } from './localization';

const fields: Localization = {
  'city.adelaide': 'Adelaide',
  'city.alberta': 'Alberta',
  'city.almaty': 'Almaty',
  'city.anchorage': 'Anchorage',
  'city.brasilia': 'Brasília',
  'city.brisbane': 'Brisbane',
  'city.cancun': 'Cancún',
  'city.cihuahua': 'Chihuahua',
  'city.darwin': 'Darwin',
  'city.denver': 'Denver',
  'city.halifax': 'Halifax',
  'city.honolulu': 'Honolulu',
  'city.houston': 'Houston',
  'city.irkutsk': 'Irkutsk',
  'city.jakarta': 'Jakarta',
  'city.kinshasa': 'Kinshasa',
  'city.losangeles': 'Los Angeles',
  'city.mexicali': 'Mexicali',
  'city.mexicocity': 'Mexico City',
  'city.moscow': 'Moscow',
  'city.newfoundland': 'Newfoundland',
  'city.novosibirsk': 'Novosibirsk',
  'city.omsk': 'Omsk',
  'city.ottawa': 'Ottawa',
  'city.perth': 'Perth',
  'city.samara': 'Samara',
  'city.sydney': 'Sydney',
  'city.tabwakea': 'Tabwakea',
  'city.tarawa': 'Tarawa',
  'city.ulanbator': 'Ulaanbaatar',
  'city.vancouver': 'Vancouver',
  'city.vladivostok': 'Vladivostok',
  'city.washington': 'Washington',
  'city.winnipeg': 'Winnipeg',
  'city.yakutsk': 'Yakutsk',
  'city.yekaterinburg': 'Yekaterinburg',
  'country.AD': 'Andorra',
  'country.AE': 'United Arab Emirates',
  'country.AF': 'Afghanistan',
  'country.AG': 'Antigua and Barbuda',
  'country.AL': 'Albania',
  'country.AM': 'Armenia',
  'country.AO': 'Angola',
  'country.AR': 'Argentina',
  'country.AT': 'Austria',
  'country.AU': 'Australia',
  'country.AZ': 'Azerbaijan',
  'country.BA': 'Bosnia and Herzegovina',
  'country.BB': 'Barbados',
  'country.BD': 'Bangladesh',
  'country.BE': 'Belgium',
  'country.BF': 'Burkina Faso',
  'country.BG': 'Bulgaria',
  'country.BH': 'Bahrain',
  'country.BI': 'Burundi',
  'country.BJ': 'Benin',
  'country.BN': 'Brunei',
  'country.BO': 'Bolivia',
  'country.BR': 'Brazil',
  'country.BS': 'Bahamas',
  'country.BT': 'Bhutan',
  'country.BW': 'Botswana',
  'country.BY': 'Belarus',
  'country.BZ': 'Belize',
  'country.CA': 'Canada',
  'country.CD': 'Congo, the Democratic Republic of the',
  'country.CF': 'Central African Republic',
  'country.CG': 'Congo',
  'country.CH': 'Switzerland',
  'country.CI': 'Ivory Coast',
  'country.CL': 'Chile',
  'country.CM': 'Cameroon',
  'country.CN': 'China',
  'country.CO': 'Colombia',
  'country.CR': 'Costa Rica',
  'country.CU': 'Cuba',
  'country.CV': 'Cape Verde',
  'country.CY': 'Cyprus',
  'country.CZ': 'Czech Republic',
  'country.DE': 'Germany',
  'country.DJ': 'Djibouti',
  'country.DK': 'Denmark',
  'country.DM': 'Dominica',
  'country.DO': 'Dominican Republic',
  'country.DZ': 'Algeria',
  'country.EC': 'Ecuador',
  'country.EE': 'Estonia',
  'country.EG': 'Egypt',
  'country.ER': 'Eritrea',
  'country.ES': 'Spain',
  'country.ET': 'Ethiopia',
  'country.FI': 'Finland',
  'country.FJ': 'Fiji',
  'country.FM': 'Micronesia',
  'country.FR': 'France',
  'country.GA': 'Gabon',
  'country.GB': 'United Kingdom',
  'country.GD': 'Grenada',
  'country.GE': 'Georgia',
  'country.GH': 'Ghana',
  'country.GM': 'Gambia',
  'country.GN': 'Guinea',
  'country.GQ': 'Equatorial Guinea',
  'country.GR': 'Greece',
  'country.GT': 'Guatemala',
  'country.GW': 'Guinea-Bissau',
  'country.GY': 'Guyana',
  'country.HN': 'Honduras',
  'country.HR': 'Croatia',
  'country.HT': 'Haiti',
  'country.HU': 'Hungary',
  'country.ID': 'Indonesia',
  'country.IE': 'Ireland',
  'country.IL': 'Israel',
  'country.IN': 'India',
  'country.IQ': 'Iraq',
  'country.IR': 'Iran',
  'country.IS': 'Iceland',
  'country.IT': 'Italy',
  'country.JM': 'Jamaica',
  'country.JO': 'Jordan',
  'country.JP': 'Japan',
  'country.KE': 'Kenya',
  'country.KG': 'Kyrgyzstan',
  'country.KH': 'Cambodia',
  'country.KI': 'Kiribati',
  'country.KM': 'Comoros',
  'country.KN': 'Saint Kitts and Nevis',
  'country.KP': "Korea, Democratic People's Republic of",
  'country.KR': 'South Korea',
  'country.KW': 'Kuwait',
  'country.KZ': 'Kazakhstan',
  'country.LA': 'Laos',
  'country.LB': 'Lebanon',
  'country.LC': 'Saint Lucia',
  'country.LI': 'Liechtenstein',
  'country.LK': 'Sri Lanka',
  'country.LR': 'Liberia',
  'country.LS': 'Lesotho',
  'country.LT': 'Lithuania',
  'country.LU': 'Luxembourg',
  'country.LV': 'Latvia',
  'country.LY': 'Libya',
  'country.MA': 'Morocco',
  'country.MC': 'Monaco',
  'country.MD': 'Moldova',
  'country.ME': 'Montenegro',
  'country.MG': 'Madagascar',
  'country.MH': 'Marshall Islands',
  'country.MK': 'Macedonia',
  'country.ML': 'Mali',
  'country.MM': 'Myanmar',
  'country.MN': 'Mongolia',
  'country.MR': 'Mauritania',
  'country.MT': 'Malta',
  'country.MU': 'Mauritius',
  'country.MV': 'Maldives',
  'country.MW': 'Malawi',
  'country.MX': 'Mexico',
  'country.MY': 'Malaysia',
  'country.MZ': 'Mozambique',
  'country.NA': 'Namibia',
  'country.NE': 'Niger',
  'country.NG': 'Nigeria',
  'country.NI': 'Nicaragua',
  'country.NL': 'Netherlands',
  'country.NO': 'Norway',
  'country.NP': 'Nepal',
  'country.NR': 'Nauru',
  'country.NZ': 'New Zealand',
  'country.OM': 'Oman',
  'country.PA': 'Panama',
  'country.PE': 'Peru',
  'country.PG': 'Papua New Guinea',
  'country.PH': 'Philippines',
  'country.PK': 'Pakistan',
  'country.PL': 'Poland',
  'country.PS': 'Palestine',
  'country.PT': 'Portugal',
  'country.PW': 'Palau',
  'country.PY': 'Paraguay',
  'country.QA': 'Qatar',
  'country.RO': 'Romania',
  'country.RS': 'Serbia',
  'country.RU': 'Russia',
  'country.RW': 'Rwanda',
  'country.SA': 'Saudi Arabia',
  'country.SB': 'Solomon Islands',
  'country.SC': 'Seychelles',
  'country.SD': 'Sudan',
  'country.SE': 'Sweden',
  'country.SG': 'Singapore',
  'country.SI': 'Slovenia',
  'country.SK': 'Slovakia',
  'country.SL': 'Sierra Leone',
  'country.SM': 'San Marino',
  'country.SN': 'Senegal',
  'country.SO': 'Somalia',
  'country.SR': 'Suriname',
  'country.SS': 'South Sudan',
  'country.ST': 'Sao Tome and Principe',
  'country.SV': 'El Salvador',
  'country.SY': 'Syria',
  'country.SZ': 'Swaziland',
  'country.TD': 'Chad',
  'country.TG': 'Togo',
  'country.TH': 'Thailand',
  'country.TJ': 'Tajikistan',
  'country.TL': 'Timor-Leste',
  'country.TM': 'Turkmenistan',
  'country.TN': 'Tunisia',
  'country.TO': 'Tonga',
  'country.TR': 'Turkey',
  'country.TT': 'Trinidad and Tobago',
  'country.TV': 'Tuvalu',
  'country.TW': 'Taiwan',
  'country.TZ': 'Tanzania',
  'country.UA': 'Ukraine',
  'country.UG': 'Uganda',
  'country.US': 'United States',
  'country.UY': 'Uruguay',
  'country.UZ': 'Uzbekistan',
  'country.VA': 'Holy See',
  'country.VC': 'St. Vincent and the Grenadines',
  'country.VE': 'Venezuela',
  'country.VN': 'Vietnam',
  'country.VU': 'Vanuatu',
  'country.WS': 'Samoa',
  'country.XK': 'Kosovo',
  'country.YE': 'Yemen',
  'country.ZA': 'South Africa',
  'country.ZM': 'Zambia',
  'country.ZW': 'Zimbabwe',
};

export default fields;
