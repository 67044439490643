import type { Localization } from './localization';
import localeDe from './de';
import localeEn from './en';

enum Locale {
  DE,
  EN,
}

let currentLocale = Locale.DE;

function getLanguageFile(locale: Locale): Localization {
  switch (locale) {
    case Locale.DE:
      return localeDe;
    case Locale.EN:
      return localeEn;
  }
}

export function getString(key: keyof Localization): string {
  return getLanguageFile(currentLocale)[key];
}
