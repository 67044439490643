import type { Localization } from './localization';

const fields: Localization = {
  'city.adelaide': 'Adelaide',
  'city.alberta': 'Alberta',
  'city.almaty': 'Almaty',
  'city.anchorage': 'Anchorage',
  'city.brasilia': 'Brasília',
  'city.brisbane': 'Brisbane',
  'city.cancun': 'Cancún',
  'city.cihuahua': 'Chihuahua',
  'city.darwin': 'Darwin',
  'city.denver': 'Denver',
  'city.halifax': 'Halifax',
  'city.honolulu': 'Honolulu',
  'city.houston': 'Houston',
  'city.irkutsk': 'Irkutsk',
  'city.jakarta': 'Jakarta',
  'city.kinshasa': 'Kinshasa',
  'city.losangeles': 'Los Angeles',
  'city.mexicali': 'Mexicali',
  'city.mexicocity': 'Mexico City',
  'city.moscow': 'Moskau',
  'city.newfoundland': 'Neufundland',
  'city.novosibirsk': 'Nowosibirsk',
  'city.omsk': 'Omsk',
  'city.ottawa': 'Ottawa',
  'city.perth': 'Perth',
  'city.samara': 'Samara',
  'city.sydney': 'Sydney',
  'city.tabwakea': 'Tabwakea',
  'city.tarawa': 'Tarawa',
  'city.ulanbator': 'Ulaanbaatar',
  'city.vancouver': 'Vancouver',
  'city.vladivostok': 'Wladiwostok',
  'city.washington': 'Washington',
  'city.winnipeg': 'Winnipeg',
  'city.yakutsk': 'Jakutsk',
  'city.yekaterinburg': 'Jekaterinburg',
  'country.AD': 'Andorra',
  'country.AE': 'Vereinigte Arabische Emirate',
  'country.AF': 'Afghanistan',
  'country.AG': 'Antigua und Barbuda',
  'country.AL': 'Albanien',
  'country.AM': 'Armenien',
  'country.AO': 'Angola',
  'country.AR': 'Argentinien',
  'country.AT': 'Österreich',
  'country.AU': 'Australien',
  'country.AZ': 'Aserbaidschan',
  'country.BA': 'Bosnien und Herzegowina',
  'country.BB': 'Barbados',
  'country.BD': 'Bangladesch',
  'country.BE': 'Belgien',
  'country.BF': 'Burkina Faso',
  'country.BG': 'Bulgarien',
  'country.BH': 'Bahrain',
  'country.BI': 'Burundi',
  'country.BJ': 'Benin',
  'country.BN': 'Brunei',
  'country.BO': 'Bolivien',
  'country.BR': 'Brasilien',
  'country.BS': 'Bahamas',
  'country.BT': 'Bhutan',
  'country.BW': 'Botswana',
  'country.BY': 'Weißrussland',
  'country.BZ': 'Belize',
  'country.CA': 'Kanada',
  'country.CD': 'Demokratische Republik Kongo',
  'country.CF': 'Zentralafrikanische Republik',
  'country.CG': 'Kongo',
  'country.CH': 'Schweiz',
  'country.CI': 'Elfenbeinküste',
  'country.CL': 'Chile',
  'country.CM': 'Kamerun',
  'country.CN': 'China',
  'country.CO': 'Kolumbien',
  'country.CR': 'Costa Rica',
  'country.CU': 'Kuba',
  'country.CV': 'Kap Verde',
  'country.CY': 'Zypern',
  'country.CZ': 'Tschechien',
  'country.DE': 'Deutschland',
  'country.DJ': 'Dschibuti',
  'country.DK': 'Dänemark',
  'country.DM': 'Dominica',
  'country.DO': 'Dominikanische Republik',
  'country.DZ': 'Algerien',
  'country.EC': 'Ecuador',
  'country.EE': 'Estland',
  'country.EG': 'Ägypten',
  'country.ER': 'Eritrea',
  'country.ES': 'Spanien',
  'country.ET': 'Äthiopien',
  'country.FI': 'Finnland',
  'country.FJ': 'Fidschi',
  'country.FM': 'Mikronesien',
  'country.FR': 'Frankreich',
  'country.GA': 'Gabun',
  'country.GB': 'Großbritannien',
  'country.GD': 'Grenada',
  'country.GE': 'Georgien',
  'country.GH': 'Ghana',
  'country.GM': 'Gambia',
  'country.GN': 'Guinea',
  'country.GQ': 'Äquatorialguinea',
  'country.GR': 'Griechenland',
  'country.GT': 'Guatemala',
  'country.GW': 'Guinea Bissau',
  'country.GY': 'Guyana',
  'country.HN': 'Honduras',
  'country.HR': 'Kroatien',
  'country.HT': 'Haiti',
  'country.HU': 'Ungarn',
  'country.ID': 'Indonesien',
  'country.IE': 'Irland',
  'country.IL': 'Israel',
  'country.IN': 'Indien',
  'country.IQ': 'Irak',
  'country.IR': 'Iran',
  'country.IS': 'Island',
  'country.IT': 'Italien',
  'country.JM': 'Jamaika',
  'country.JO': 'Jordanien',
  'country.JP': 'Japan',
  'country.KE': 'Kenia',
  'country.KG': 'Kirgistan',
  'country.KH': 'Kambodscha',
  'country.KI': 'Kiribati',
  'country.KM': 'Komoren',
  'country.KN': 'St. Kitts und Nevis',
  'country.KP': 'Nordkorea',
  'country.KR': 'Südkorea',
  'country.KW': 'Kuwait',
  'country.KZ': 'Kasachstan',
  'country.LA': 'Laos',
  'country.LB': 'Libanon',
  'country.LC': 'St. Lucia',
  'country.LI': 'Liechtenstein',
  'country.LK': 'Sri Lanka',
  'country.LR': 'Liberia',
  'country.LS': 'Lesotho',
  'country.LT': 'Litauen',
  'country.LU': 'Luxemburg',
  'country.LV': 'Lettland',
  'country.LY': 'Libyen',
  'country.MA': 'Marokko',
  'country.MC': 'Monaco',
  'country.MD': 'Moldau',
  'country.ME': 'Montenegro',
  'country.MG': 'Madagaskar',
  'country.MH': 'Marshallinseln',
  'country.MK': 'North Macedonia',
  'country.ML': 'Mali',
  'country.MM': 'Myanmar',
  'country.MN': 'Mongolei',
  'country.MR': 'Mauretanien',
  'country.MT': 'Malta',
  'country.MU': 'Mauritius',
  'country.MV': 'Malediven',
  'country.MW': 'Malawi',
  'country.MX': 'Mexiko',
  'country.MY': 'Malaysia',
  'country.MZ': 'Mosambik',
  'country.NA': 'Namibia',
  'country.NE': 'Niger',
  'country.NG': 'Nigeria',
  'country.NI': 'Nicaragua',
  'country.NL': 'Niederlande',
  'country.NO': 'Norwegen',
  'country.NP': 'Nepal',
  'country.NR': 'Nauru',
  'country.NZ': 'Neuseeland',
  'country.OM': 'Oman',
  'country.PA': 'Panama',
  'country.PE': 'Peru',
  'country.PG': 'Papua-Neuguinea',
  'country.PH': 'Philippinen',
  'country.PK': 'Pakistan',
  'country.PL': 'Polen',
  'country.PS': 'Palästina',
  'country.PT': 'Portugal',
  'country.PW': 'Palau',
  'country.PY': 'Paraguay',
  'country.QA': 'Katar',
  'country.RO': 'Rumänien',
  'country.RS': 'Serbien',
  'country.RU': 'Russland',
  'country.RW': 'Ruanda',
  'country.SA': 'Saudi-Arabien',
  'country.SB': 'Salomonen',
  'country.SC': 'Seychellen',
  'country.SD': 'Sudan',
  'country.SE': 'Schweden',
  'country.SG': 'Singapur',
  'country.SI': 'Slowenien',
  'country.SK': 'Slowakei',
  'country.SL': 'Sierra Leone',
  'country.SM': 'San Marino',
  'country.SN': 'Senegal',
  'country.SO': 'Somalia',
  'country.SR': 'Suriname',
  'country.SS': 'Südsudan',
  'country.ST': 'São Tomé und Príncipe',
  'country.SV': 'El Salvador',
  'country.SY': 'Syrien',
  'country.SZ': 'Eswatini',
  'country.TD': 'Tschad',
  'country.TG': 'Togo',
  'country.TH': 'Thailand',
  'country.TJ': 'Tadschikistan',
  'country.TL': 'Osttimor',
  'country.TM': 'Turkmenistan',
  'country.TN': 'Tunesien',
  'country.TO': 'Tonga',
  'country.TR': 'Türkei',
  'country.TT': 'Trinidad und Tobago',
  'country.TV': 'Tuvalu',
  'country.TW': 'Taiwan',
  'country.TZ': 'Tansania',
  'country.UA': 'Ukraine',
  'country.UG': 'Uganda',
  'country.US': 'USA',
  'country.UY': 'Uruguay',
  'country.UZ': 'Usbekistan',
  'country.VA': 'Vatican',
  'country.VC': 'St. Vincent und die Grenadinen',
  'country.VE': 'Venezuela',
  'country.VN': 'Vietnam',
  'country.VU': 'Vanuatu',
  'country.WS': 'Samoa',
  'country.XK': 'Kosovo',
  'country.YE': 'Jemen',
  'country.ZA': 'Südafrika',
  'country.ZM': 'Sambia',
  'country.ZW': 'Simbabwe',
};

export default fields;
