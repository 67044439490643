import placesByUtcOffset from '../data/places';
import PlaceBox from './place';
import Timer from './timer';

interface TimeZoneGroupProps {
  utcOffset: number;
}

function getTimeZoneString(utcOffset: number): string {
  if (utcOffset === 0) return 'UTC';
  if (utcOffset < 0) return 'UTC' + utcOffset;
  return 'UTC+' + utcOffset;
}

export default function TimeZoneGroup(props: TimeZoneGroupProps) {
  const { utcOffset } = props;
  const places = placesByUtcOffset.get(utcOffset)!;

  return (
    <section className="mt-14 max-w-4xl">
      <h2 className="font-black text-4xl">
        {getTimeZoneString(utcOffset)}
      </h2>
      <Timer utcOffset={utcOffset} />
      <div className="
        flex flex-wrap gap-y-10 gap-x-4 justify-around
        mt-2">
        {
          places.map(place => <PlaceBox key={place.countryCode} place={place} />)
        }
      </div>
    </section>
  );
}
